import React from "react"
import { Link } from "gatsby"
import { Helmet } from "react-helmet"

const Discipline = () => {
  return (
    <div className="container">
      <Helmet>
        <title> The Shoyaright! </title>
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@zuffshoya" />
        <meta name="twitter:title" content="Discipline: Why? & How?" />
        <meta
          name="twitter:image"
          content="http://shoya.co/static/shoyaright_icon_twitter_card_large_summary-ce9c728db92ba633cc195662666f7a8f.png"
        />
        <meta name="twitter:image:alt" content="The Shoyaright! Logo" />
      </Helmet>
      <link rel="stylesheet" href="../styles/global.css" />
      <div className="article">
        <Link to="/shoyaright">
          <div className="shoyarightChomskyArticle" />
        </Link>
        <div className="articleTitle">
          <p>Discipline: Why? & How?</p>
        </div>
        <div className="byLine">
          <p> By: Lil Shoyaright! (Pub. Oct. 13, 2021) </p>
        </div>
        <div className="articleBody">
          <p>
            A UFO descends from the heavens over Belmont Plateau, piloted by the
            one-and-only Spacebug.{" "}
          </p>
          <p>
            It creates a fog as it lands. It's making noises and shit. The front
            hatch opens, releasing even more fog. A silhouette begins to descend
            to the surface.{" "}
          </p>
          <p>Over a loudspeaker the UFO declares in a robotic voice: </p>
          <p>"ZZZZZKHHHH [[[SUSPENDING___GIMMICK]]] KHHHZZZZZ".</p>
          <p>
            The fog clears as the silhouette sets foot on the surface and
            obviously it's me tf lmao I had to put a pause on the shtick for
            this one, I know y'all know I'm supposed to be a literal
            trillion-dollar company whatever hardy har but here go some real
            spill.
          </p>
          <p className="highlight">I. Why Does the Caged Bird Sing?</p>
          <p>
            So recently I had been feeling a lil self-conscious about my
            presence on Twitter. I don't post nearly as much as I used to, but
            whenever I did I felt like I would sometimes (unironically) sound
            like one of those #riseandgrind entrepeneur IG pages.
          </p>
          <p>
            How did this happen to me? Was my precious Internet gimmick rotting
            my brain?
          </p>
          <p>
            I did some introspecting, and thankfully the answer to that second
            question is 'No.' Far as that first question...that's too tricky to
            say for sure but I think it's cuz my frontal lobes getting even
            sturdier w/ age lol.
          </p>
          <p>
            My principles are still the same as they've been. In fact, they're
            even more refined now, and become more specific damn near every week
            (hit me on Signal if you want more details than that, duh).
          </p>
          <p>
            The key realization for me was that{" "}
            <span className="editorsNote">THE GRIND®</span>{" "}itself actually has
            no valence, no inherent opinion.{" "}
            <span className="editorsNote">THE GRIND®</span> is Swiss, so to
            speak. It's why you deploy it that matters. Building a new world
            is...uh...<i>effortful</i>, and I'm convinced there's a CIA psyop in
            progress to get us all to dissociate completely from our productive
            urges so that we never get around to building it. (I'm thinking of
            the whole 'I don't dream of labor' and{" "}
            <a
              href="https://www.bbc.com/news/world-asia-china-57348406"
              target="_blank"
              rel="noopener noreferrer"
            >
              lying flat
            </a>{" "}
            phenomema — I understand those impulses but I'm hoping that lying
            flat is just a precursor to standing upright word to my dog{" "}
            <a
              href="https://vimeo.com/46137917"
              target="_blank"
              rel="noopener noreferrer"
            >
              Sankara
            </a>
            !!!)
          </p>
          <p>
            So yeah, I've become someone who values discipline. I keep my word. I wake up early. I work, I exercise, I
            study. Not because of some weirdo materialistic drive, but because I
            aspire to be an asset to the ones I love and the causes I believe
            in.
          </p>
          <p>
            The caged bird sings to harmonize with the other birds in the other cages,
            and only between pecking relentlessly at the bars of its own.
          </p>
          <p className="highlight">
            II. How to Move in a Room Full of Vultures
          </p>
          <p>
          The 'room full of vultures' in question here is climate change you ain't see that one coming did you.
          </p>
          <p>
            Just a few months ago, Greenpeace{" "}
            <a
              href="https://unearthed.greenpeace.org/2021/06/30/exxon-climate-change-undercover/"
              target="_blank"
              rel="noopener noreferrer"
            >
              released video{" "}
            </a>{" "}
            of an ExxonMobil lobbyist admitting to manipulating Congress as part
            of a 40-year disinformation war against climate science. That would
            make me extremely angry if I didn't own both ExxonMobil <i>and</i> Congress
            (brief suspension of the suspension of the shtick I'm sorry).
          </p>
          <p>
            This was around the same time that the UN's Intergovernmental Panel
            on Climate Change released the{" "}
            <a
              href="https://www.ipcc.ch/report/ar6/wg1"
              target="_blank"
              rel="noopener noreferrer"
            >
              sixth installment{" "}
            </a>
            of they lil 'Assessment Reports'. I gave that jawn a skimarooni
            and....yeah we are super fucked it's looking like curtains.
          </p>
          <p>
            On the surface, all that context undercuts the argument in favor of
            discipline. Why shouldn't we do-for-self and pleasure-seek to the
            max if all we got left is a few years before a molten apocalypse?
          </p>
          <p>
            It's a perfectly reasonable question, but let me clarify the paradox
            of pleasure-seeking: those who indulge in pleasure the most actually
            experience it the least. That's not me waxing philosophic, it's a{" "}
            <a
              href="https://pubmed.ncbi.nlm.nih.gov/16566899/"
              target="_blank"
              rel="noopener noreferrer"
            >
              neuroscientific fact
            </a> 
             {" "}(a good concept to be aware of here is the{" "}
            <a
              href="https://en.wikipedia.org/wiki/Hedonic_treadmill"
              target="_blank"
              rel="noopener noreferrer"
            >
              hedonic treadmill
            </a>
            ). In other words, practicing restraint is actually crucial to{" "}
            <a
              href="https://www.youtube.com/watch?v=7hJb9MxI2NY"
              target="_blank"
              rel="noopener noreferrer"
            >
              maximizing pleasure{" "}
            </a>{" "}
            .
          </p>
          <p>
            So by all means, if your response to the climate catastrophe is to
            become a selfish masturbator, do you. All I'm saying is that if
            that's what any of y'all choose to be, at least be <i>good</i> at
            it.
          </p>
          <p>
            And ard here go some basic protocols: </p>
            <p> 
            <span className="editorsNote">1</span>. Get adequate sleep. <br />
            <span className="editorsNote">2</span>. Go outside and view sunlight shortly after waking every morning.{" "}
            <br />
            <span className="editorsNote">3</span>. Abstain from eating 1 hour after waking and 3 hours before sleeping. <br />
            <span className="editorsNote">4</span>. Exercise as much as you can.
          </p>
          <p>
            If you're able to commit to those actions, you'll inevitably build a
            solid foundation on which to build a joyful and fulfilling life despite the vultures overhead (the vultures I named and those I didn't).
          </p>
          <p>
            Hit me at <a href="mailto:zuff@shoya.co">zuff@shoya.co</a> if y'all
            need any more resources on this, but for now I'm getting back on the
            UFO 🤝💯.
          </p>
        </div>
        <p className="plug">
          If you enjoyed this article, consider{" "}
          <a href="https://www.patreon.com/join/shoyaright?">
            becoming a patron
          </a>{" "}
          or making a{" "}
          <a href="https://www.paypal.com/cgi-bin/webscr?cmd=_donations&business=zuff@shoya.co&lc=US&item_name=The%20Shoyaright%21&currency_code=USD&no_note=0&bn=PP-DonationsBF:btn_donateCC_LG.gif:NonHostedGuest">
            one-time pledge
          </a>
          . Support corporate journalism!
        </p>
        <div className="articleCopyright">
          <p>&copy; Shoya, Inc. 2021</p>
        </div>
      </div>
    </div>
  )
}
export default Discipline
